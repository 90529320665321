.App {
	min-width: 700px;
	max-width: 1000px;
	margin: 10px auto 20px;
	padding: 20px
}


.box {
	text-align: center;
	padding: 30px;
	border-radius: 20px;
	box-shadow: inset 0 0 17px 8px #8080807d;
}

.rogRes {
	padding-left: 30px;
	padding-top: 30px;
	font-weight: bold;
	font-size: 30px;
}
.keyRes {
	padding-left: 30px;
	padding-top: 30px;
	font-weight: bold;
	font-size: 20px;
}

.uha  {
	font-size: 20px;
}